.modify{
    text-align: center;
    cursor: pointer;
    color: #40a9ff;
    margin-left: 10px;
    margin-right: 10px;
}

.del{
    text-align: center;
    cursor: pointer;
    color: #FF0033;
    margin-left: 10px;
    margin-right: 10px;
}